// Header
import Header from './header';
import GoBack from './goBack';
import Reframe from './reframe';
import Swiper from './swiper';
import AccordionFaq from './accordion';
import Modal from './modal';
import Forms from './forms/index';
import Pricing from './pricing/index';
import Video from './video/index';
import Filter from './filter/index';

export default [
  Forms,
  Header,
  GoBack,
  Reframe,
  Swiper,
  AccordionFaq,
  Modal,
  Pricing,
  Video,
  Filter,
];
