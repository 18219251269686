import industries from 'components/swiper/industries';
import pricing from 'components/swiper/pricing';

const init = () => {
  industries.init();
  pricing.init();
};

export default {
  init,
};
