const init = () => {
  const forms = document.querySelectorAll('.js-modal-form.js-show-schedule');
  forms.forEach((formEl) => {
    formEl.addEventListener('onAfterFormieSubmit', (e) => {
      e.preventDefault();
      setTimeout(() => {
        window.location = `${window.location.href}?thanks-for-request=1`;
      }, 1000);
    });
  });
};

export default {
  init,
};
