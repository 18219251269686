import refreshCsrfFields from './refreshCsrfFields';
import showSchedulePopup from './showSchedulePopup';
import closeFormModals from './closeFormModals';

const init = () => {
  refreshCsrfFields.init();
  showSchedulePopup.init();
  closeFormModals.init();
};
export default {
  init,
};
