import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const init = () => {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.swiper-container.home-industries-inner-slider', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      100: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  });
};

export default {
  init,
};
