const init = () => {
  const filterForm = document.getElementById('js-filter-form');
  if (filterForm) {
    filterForm.addEventListener('click', () => {
      function close() {
        const formsClose = document.querySelectorAll('.js-form-close');
        formsClose.forEach((el) => {
          el.addEventListener('click', () => {
            const id = el.getAttribute('data-form');
            const btnOff = document.getElementById(id);
            btnOff.click();
          });
        });
      } setTimeout(close, 1000);
    });
  }
};

export default {
  init,
};
