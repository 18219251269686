/* eslint-disable consistent-return, no-param-reassign */
const init = () => {
  const planToggler = document.querySelector('.js-pricing-plan-toggler');
  const priceLabels = document.querySelectorAll('.js-pricing-label');
  const priceValues = document.querySelectorAll('.js-pricing-value');

  if (planToggler == null) {
    return false;
  }

  const setYearPrices = () => {
    priceValues.forEach((el) => {
      el.innerHTML = el.dataset.yearPrice;
    });
    priceLabels.forEach((el) => {
      el.innerHTML = el.dataset.yearLabel;
    });
  };

  const setYMonthPrices = () => {
    priceValues.forEach((el) => {
      el.innerHTML = el.dataset.monthPrice;
    });
    priceLabels.forEach((el) => {
      el.innerHTML = el.dataset.monthLabel;
    });
  };

  planToggler.addEventListener('change', () => {
    if (planToggler.checked) {
      setYearPrices();
    } else {
      setYMonthPrices();
    }
  }, { passive: true });
};

export default {
  init,
};
