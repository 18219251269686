import Swiper from 'swiper';

const init = () => {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.js-pricing-swiper', {
    spaceBetween: 16,
    slidesPerView: 1.25,
    centeredSlides: true,
    initialSlide: 1,
    slideToClickedSlide: true,
    allowTouchMove: true,
    breakpoints: {
      480: {
        spaceBetween: 24,
        slidesPerView: 1.5,
        centeredSlides: true,
        initialSlide: 1,
        slideToClickedSlide: true,
        allowTouchMove: true,
      },
      640: {
        spaceBetween: 24,
        slidesPerView: 2,
        centeredSlides: true,
        initialSlide: 1,
        slideToClickedSlide: true,
        allowTouchMove: true,
      },
      860: {
        spaceBetween: 24,
        slidesPerView: 2.25,
        centeredSlides: true,
        initialSlide: 1,
        slideToClickedSlide: true,
        allowTouchMove: true,
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3,
        centeredSlides: true,
        initialSlide: 1,
        slideToClickedSlide: false,
        allowTouchMove: false,
      },
    },
  });
};

export default {
  init,
};
