import '../styles/main.scss';

import * as Sentry from '@sentry/browser';
import 'lazysizes';
import Components from '../components';

Sentry.init({
  dsn: 'https://3c8acca134304ec78d50b5f2a5452c98@sentry.yournext.agency/55',
});

if (Components.length) {
  Components.forEach((component) => component.init());
}
