const init = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('form:not(.js-filter-form)');
    if (forms.length) {
      fetch('/actions/blitz/csrf/input')
        .then((result) => result.text())
        .then((result) => {
          forms.forEach((form) => {
            const submitBtn = form.querySelector('button[type="submit"]');
            if (submitBtn !== null) {
              submitBtn.setAttribute('disabled', true);
            }
            const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
            csrfInput.outerHTML = result;
            form.classList.add('is-updated');
            if (submitBtn !== null) {
              submitBtn.removeAttribute('disabled');
            }
          });
        });
    }
  });
};
export default {
  init,
};
