let header;
let responsiveBtn;
let menuWrapper;
let stickyHeader;

function scrollToElement(element, offset) {
  window.scrollTo({
    behavior: 'smooth',
    top: element.offsetTop - offset,
  });
}

function scrollToElementIfRequired(offset, elHash, elId) {
  const { hash } = window.location;
  if (hash === elHash) {
    const industriesSection = document.getElementById(elId);
    if (industriesSection) {
      scrollToElement(industriesSection, offset);
    }
  }
}

function toggleMenu() {
  header.classList.toggle('header-opened');
  responsiveBtn.classList.toggle('opened');
  menuWrapper.style.display = (menuWrapper.style.display === 'flex') ? 'none' : 'flex';
}
const init = () => {
  header = document.querySelector('.header');
  responsiveBtn = document.querySelector('.header-mobile-menu');
  menuWrapper = document.querySelector('.header__nav-menu');

  if (header) {
    responsiveBtn.addEventListener('click', toggleMenu);

    const menuItems = document.querySelectorAll('.menu-item:not(.js-nested-nav-item)');
    if (menuItems.length) {
      Array.from(menuItems).forEach((el) => {
        el.addEventListener('click', () => {
          if (document.querySelector('.header-opened')) {
            toggleMenu();
          }
        });
      });
    }

    const sticky = header.offsetTop + 10;

    stickyHeader = () => {
      if (window.pageYOffset >= sticky) {
        header.classList.add('header-scrolled');
      } else {
        header.classList.remove('header-scrolled');
      }
    };

    window.onscroll = () => {
      stickyHeader();
    };

    const offset = 80;
    const hash = '#industries';
    const elementId = 'industries';

    window.addEventListener('DOMContentLoaded', () => {
      scrollToElementIfRequired(offset, hash, elementId);
    });

    window.addEventListener('load', () => {
      scrollToElementIfRequired(offset, hash, elementId);
    });

    const mq = window.matchMedia('(max-width: 992px)');
    const industriesLink = document.querySelector(`a[href='/${hash}']`);
    if (industriesLink && !mq.matches) {
      industriesLink.addEventListener('click', (event) => {
        const industriesSection = document.getElementById(elementId);
        if (!industriesSection) {
          return;
        }
        event.preventDefault();
        if (!window.location.hash) {
          window.location.hash = hash;
        }
        if (industriesSection) {
          scrollToElement(industriesSection, offset);
        }
      });
    }

    if (mq.matches) {
      const nestedNavItems = document.querySelectorAll('.js-nested-nav-item');
      nestedNavItems.forEach((item) => {
        item.addEventListener('click', (e) => {
          const currentTarget = e.target;
          const parentLink = item.querySelector('.js-parent-link');
          if (currentTarget === parentLink) {
            e.preventDefault();
          }
          if (parentLink !== null) {
            item.classList.toggle('is-active');
          }
        }, true);
      });
    }
  }
};

export default {
  init,
};
