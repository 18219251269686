let accordion;

const init = () => {
  accordion = document.querySelector('.accordion');

  if (accordion) {
    const acc = document.getElementsByClassName('course-accordion');
    let i;

    for (i = 0; i < acc.length; i += 1) {
      acc[i].onclick = function accordionFunc() {
        const panel = this.nextElementSibling;
        const coursePanel = document.getElementsByClassName('course-panel');
        const courseAccordionActive = document.getElementsByClassName('course-accordion active');
        const px = 'px';

        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          this.classList.remove('active');
        } else {
          for (let ii = 0; ii < courseAccordionActive.length; ii += 1) {
            courseAccordionActive[ii].classList.remove('active');
          }
          for (let iii = 0; iii < coursePanel.length; iii += 1) {
            this.classList.remove('active');
            coursePanel[iii].style.maxHeight = null;
          }
          panel.style.maxHeight = panel.scrollHeight + px;
          this.classList.add('active');
        }
      };
    }
  }
};

export default {
  init,
};
