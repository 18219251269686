const init = () => {
  const openModalButtons = document.querySelectorAll('.js-modal-open');

  openModalButtons.forEach((btn) => {
    const { modalId } = btn.dataset;
    const modal = document.querySelector(`#${modalId}`);
    const mainElement = document.querySelector('main');
    if (modal !== null) {
      const overlay = modal.querySelector('.js-modal-overlay');
      const close = modal.querySelector('.js-modal-close');

      const openModal = () => {
        modal.style.display = 'flex';
        if (modal.closest('main')) {
          mainElement.style.zIndex = '10';
          mainElement.style.position = 'relative';
        }
      };

      const closeModal = () => {
        modal.style.display = 'none';
        mainElement.style.zIndex = null;
        mainElement.style.position = null;
      };

      btn.addEventListener('click', (event) => {
        event.preventDefault();
        openModal();
      });

      close.addEventListener('click', () => {
        closeModal();
      });

      overlay.addEventListener('click', () => {
        closeModal();
      });

      window.addEventListener('click', (event) => {
        if (event.target === overlay) {
          closeModal();
        }
      });
    }
  });

  // const modalFormDownload = () => {
  //   const forms = document.querySelectorAll('.js-download-form');
  //   forms.forEach((formEl) => {
  //     formEl.addEventListener('onAfterFormieSubmit', (e) => {
  //       e.preventDefault();
  //       setTimeout(() => {
  //         window.location = `${window.location.href}?thanks-for-download=1`;
  //       }, 1000);
  //     });
  //   });
  // };
  // modalFormDownload();
};

export default {
  init,
};
