const init = () => {
  const forms = document.querySelectorAll('form');
  if (forms.length) {
    forms.forEach((form) => {
      form.addEventListener('onAfterFormieSubmit', () => {
        const modal = form.closest('.modal');
        if (modal !== null) {
          if (modal.dataset.closeAfterSubmit) {
            const close = modal.querySelector('.js-modal-close');
            if (close !== null) {
              setTimeout(() => {
                close.click();
              }, 2500);
            }
          }
        }
      });
    });
  }
};
export default {
  init,
};
